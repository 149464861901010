body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


& h2 {
    margin-bottom: 16px;
    font-size: 24px;
}

button {
    background-color: white;
    color: black;
    padding: 4px 12px;
    border: 1px solid #999;
    border-radius: 4px;
    cursor: pointer;

}

button:hover {
    background-color: #f9f9f9;
}

button.action-button {
    background-color: blue;
    color: white;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    cursor: pointer;
}

input[type=text], input[type=file], input[type=email], input[type=password], input[type=file], input[type=tel], input[type=number], input[type=datetime-local], select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: 'Arial', sans-serif;
}


textarea {
    width: 100%;
    height: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: 'Arial', sans-serif;
}

button[type=submit] {
    background-color: blue;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=checkbox], input[type=radio] {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff;
    border: 1px solid #999;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    position: relative;
    transition: background-color 0.1s;
    cursor: pointer;

    &:checked {
        background-color: #242F57;

        &::before {
            content: '';
            position: absolute;
            width: 10px;
            height: 5px;
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
        }
    }
}